<template>
  <div>
    <!-- section-12  -->
    <v-container class="mb-60 name">
      <v-row>
        <v-col cols="12">
          <div class="d-flex justify-space-between mb-5" />
          <v-card-title>
            <v-breadcrumbs class="text-uppercase font-weight-bolder" :items="itemsLink" />
            <v-spacer />
          </v-card-title>

          <v-col cols="12">
            <v-row>
              <v-divider />
            </v-row>
          </v-col>

          <v-container>
            <v-card-text class="ps-6 d-flex justify-space-between align-center flex-wrap">
              <div class="my-2">
                <h4 class="text-uppercase">
                  Todas Categorias
                </h4>
                <p v-if="categories.length > 1" class="gray--text text--darken-1 mb-0">
                  <span class="font-weight-bold"> {{ categories.length }}</span>
                  Categorias Listadas
                </p>
              </div>
              <div class="d-flex align-center flex-wrap">
                <div class="grey--text text--darken-1 me-2 my-2">
                  Ver:
                </div>
                <v-btn title="Ver Categorias em Forma de Grade" icon @click="showDefaultList('default')">
                  <img v-svg-inline class="icon" src="@/assets/images/icons/grid.svg" alt="">
                </v-btn>
                <v-btn title="Ver Categorias em Forma de Lista" icon @click="showDefaultList('list')">
                  <img v-svg-inline class="icon" src="@/assets/images/icons/menu.svg" alt="">
                </v-btn>
              </div>
            </v-card-text>
            <v-row v-if="loading">
              <v-col cols="12">
                <v-progress-linear indeterminate color="secondary" />
              </v-col>
            </v-row>
            <v-row v-if="defaultLayout=='default' && categories.length>0">
              <v-col v-for="(categoria, index) in categories" :key="index" cols="12" sm="6" md="4" lg="3" xl="2">
                <div class="m-5">
                  <v-col cols="12" class="text-center">
                    <router-link :to="{ name: 'ProdutosPorCategoria', params: { uuid: categoria.uuid } }" class="text-decoration-none blue-grey--text darken-1">
                      <v-card elevation="0" :title="' Ver todos os Produtos associados a categoria: '+categoria.designacao" class="shadow-sm br-8 text-truncate" max-width="100%" :class="cardHoverShadow ? 'card-hover-shadow' : ''">
                        <v-card-text class="justify-center">
                          <v-avatar tile size="150" class="mr-2 text-center">
                            <v-icon size="80" color="secondary">
                              {{ categoria.icon?categoria.icon:'mdi-home' }}
                            </v-icon>
                          </v-avatar>

                          <h5 class="grey--text text--darken-4 text-center">
                            {{ limitText(categoria.designacao) }}
                            ({{ categoria.produtos_count }})
                          </h5>
                        </v-card-text>
                      </v-card>
                    </router-link>
                    <!-- </v-card-text>
                    </v-card> -->
                  </v-col>
                </div>
              </v-col>
            </v-row>
            <v-container v-if="defaultLayout!='default'">
              <v-row class="name">
                <!-- First V-Card -->
                <v-col cols="12">
                  <transition-group name="fade" mode="out-in">
                    <v-card v-for="produto in categories" :key="produto.id" :title="' Ver todos os Produtos associados a categoria: '+produto.designacao" elevation="0" class="mb-2">
                      <router-link :to="{ name: 'ProdutosPorCategoria', params: { uuid: produto.uuid } }" class="text-decoration-none blue-grey--text darken-1">
                        <v-card-text class="ps-6 d-flex justify-space-between align-center flex-wrap">
                          <v-list-item>
                            <v-list-item-avatar size="100" rounded>
                              <v-icon size="80" color="secondary">
                                {{ produto.icon?produto.icon:'mdi-home' }}
                              </v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title class="font-weight-bold text-uppercase">
                                {{ produto.designacao }}({{ produto.produtos_count }})
                              </v-list-item-title>
                            </v-list-item-content>
                            <!-- </transition-group> -->
                          </v-list-item>
                        </v-card-text>
                      </router-link>
                    </v-card>
                  </transition-group>
                </v-col>
              </v-row>
            </v-container>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
//   import CardCart from '@/components/cartCard/CardCart'
  import {
    LOGIN_URL,
  } from '@/configs/api'
  import axios from 'axios'

  export default {
    components: {
      //   CardCart,
    },
    data () {
      return {

        defaultLayout: 'default',
        itemsLink: [{
                      text: 'Início',
                      disabled: false,
                      href: '/',
                    },
                    {
                      text: 'Todas Categorias',
                      disabled: true,
                      href: '/portal/pages/categorias',
                    },

        ],

        cardHoverShadow: false,
        categories: [],
        loading: false,
        produtos: [],
        tab: null,
      }
    },
    computed: {

    },
    created () {
      window.addEventListener('scroll', this.handleScroll)
    },

    mounted () {
      this.getAllCategories()
    },

    destroyed () {
      window.removeEventListener('scroll', this.handleScroll)
    },

    methods: {
      limitText (texto, limite = 20) {
        if (texto.length > limite) {
          return texto.substring(0, limite) + '...'
        }
        return texto
      },
      showDefaultList (params) {
        this.defaultLayout = params
      },
      getAllCategories () {
        this.loading = true
        axios
          .get(LOGIN_URL + '/listarCategorias')
          .then((response) => {
            this.categories = response.data
            // alert(JSON.stringify(this.categories))
            this.loading = false
          })
      },
    },
  }
</script>

<style lang="scss" scoped>
.br-t-8 {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.card-hover {
  &:hover {
    .card-hover-icon {
      display: block;
    }
  }

  .card-hover-icon {
    display: none;
  }
}
</style><style>
.custom-link {
  text-decoration: none;
  /* Remove default text decoration */
  color: #000;
  /* Set the link color */
}

.custom-link:visited {
  /* Style for visited link (after it has been clicked) */
  text-decoration: none;
  /* Remove text decoration */
  color: #555;
  /* Set the color for visited links */
}
</style>
