import { render, staticRenderFns } from "./CategoriasIndex.vue?vue&type=template&id=58b6e7d0&scoped=true&"
import script from "./CategoriasIndex.vue?vue&type=script&lang=js&"
export * from "./CategoriasIndex.vue?vue&type=script&lang=js&"
import style0 from "./CategoriasIndex.vue?vue&type=style&index=0&id=58b6e7d0&prod&lang=scss&scoped=true&"
import style1 from "./CategoriasIndex.vue?vue&type=style&index=1&id=58b6e7d0&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58b6e7d0",
  null
  
)

export default component.exports